import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchVendors = createAsyncThunk(
  "vendor/fetchVendors",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/vendors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchVendorById = createAsyncThunk(
  "vendor/fetchVendorById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get(`/admin/view-vendor/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchApprovedProducts = createAsyncThunk(
  "vendor/fetchApprovedProducts",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/approved-products", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPendingProduct = createAsyncThunk(
  "vendor/fetchPendingProduct",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/vendors/pending-approval", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveVendorProduct = createAsyncThunk(
  "vendor/approveVendorProduct",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(`/vendors/approve-product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const producersSlice = createSlice({
  name: "vendor",
  initialState: {
    vendors: [],
    vendorDetails: null,
    approvedProducts: [],
    pendingProducts: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVendors.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchVendors.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.vendors = action.payload.data;
      })
      .addCase(fetchVendors.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchVendorById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchVendorById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.vendorDetails = action.payload;
      })
      .addCase(fetchVendorById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchApprovedProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchApprovedProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approvedProducts = action.payload;
      })
      .addCase(fetchApprovedProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchPendingProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPendingProduct.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pendingProducts = action.payload;
      })
      .addCase(fetchPendingProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(approveVendorProduct.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveVendorProduct.fulfilled, (state) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(approveVendorProduct.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default producersSlice.reducer;
