import React, { useState } from "react";
import { ImSpinner2 } from "react-icons/im";
import {
  formatCreatedAt,
  formatNumberWithCommas,
  formatTime,
} from "../utils/commonUtils";

const UserTransactions = ({ status, error, userTransactions, userDetail }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;

  const [filterType, setFilterType] = useState("");

  const filteredTransactions = userTransactions?.filter(
    (transaction) =>
      filterType === "" ||
      transaction.transaction_type.toLowerCase() === filterType.toLowerCase()
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="w-full">
      <div className="w-full px-6 py-6 bg-white rounded-lg shadow">
        <div className="flex justify-between items-center bg-gray-100 p-2 border-b">
          <div className="flex items-center gap-4">
            <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
              Transactions
            </h1>
            <select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              className="px-4 py-2 border rounded-md"
            >
              <option value="">All Type</option>
              <option value="credit">Credit</option>
              <option value="debit">Debit</option>
            </select>
          </div>
          <div>
            <p
              className={`text-lg font-semibold ${
                userDetail?.data?.profitloss.includes("Loss")
                  ? "text-red-500"
                  : "text-green-500"
              }`}
            >
              {userDetail?.data?.profitloss.includes("Loss")
                ? `Loss: ₦ ${formatNumberWithCommas(
                    userDetail?.data?.profitloss.replace("Loss: ", "")
                  )}`
                : `Profit: ₦ ${formatNumberWithCommas(
                    userDetail?.data?.profitloss.replace("Profit: ", "")
                  )}`}
            </p>
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="w-full text-sm">
            <thead className="bg-gray-100">
              <tr className="border-b text-center">
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  No.
                </th>
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  Description
                </th>
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  Amount
                </th>
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  Type
                </th>
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  Created
                </th>
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  Time
                </th>
                <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                  Updated
                </th>
              </tr>
            </thead>
            <tbody>
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="m-auto">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : (
                currentTransactions.map((transaction, index) => (
                  <tr key={transaction.id} className="border-b text-center">
                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                      {indexOfFirstItem + index + 1}
                    </td>
                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                      {transaction.description}
                    </td>
                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(transaction.amount || "0")}
                    </td>
                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${
                          transaction.transaction_type === "credit"
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {transaction.transaction_type === "credit"
                          ? "Credit"
                          : "Debit"}
                      </span>
                    </td>
                    <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                      {formatCreatedAt(transaction.created_at)}
                    </td>
                    <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                      {formatTime(transaction.created_at)}
                    </td>
                    <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                      {formatCreatedAt(transaction.updated_at)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-4 py-2">
          <button
            onClick={prevPage}
            disabled={currentPage === 1}
            className={`px-4 py-2 rounded-md ${
              currentPage === 1
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            Previous
          </button>
          <button
            onClick={nextPage}
            disabled={currentPage === totalPages}
            className={`px-4 py-2 rounded-md ${
              currentPage === totalPages
                ? "bg-gray-300 cursor-not-allowed"
                : "bg-blue-500 hover:bg-blue-600"
            }`}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserTransactions;
