import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { PiPlusBold } from "react-icons/pi";
import { toast } from "react-toastify";

import { fetchAutoMessage, setAutoEmail } from "../../../redux/slices/ceoSlice";

export default function ModalAutoEmailMessage() {
  const [showModal, setShowModal] = useState(false);
  const status = useSelector((state) => state.ceo?.autoEmailStatus);

  const dispatch = useDispatch();

  const initialFormData = {
    message: "",
    subject: "",
  };

  const [formData, setFormData] = useState({ ...initialFormData });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(setAutoEmail(formData));

      setFormData({ ...initialFormData });

      await dispatch(fetchAutoMessage());
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }
    setShowModal(false);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className="flex gap-[8px] bg-[#1F7BF4] text-white items-center py-2 px-4 text-[14px] hover:shadow-lg rounded-[8px] font-medium"
      >
        <PiPlusBold className=" text-lg font-extrabold" />
        Auto Email Message
      </button>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center">
                  <h1 className="text-2xl text-gray-800 text-center font-medium mb-4">
                    Auto Email Message
                  </h1>
                </div>
                <div className="flex flex-col items-center px-8 py-4 gap-6">
                  <div className="flex gap-4">
                    <div>
                      <label
                        htmlFor="measege"
                        className="text-[14px] text-center text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Subject
                      </label>

                      <textarea
                        name="subject"
                        id="subject"
                        cols="30"
                        rows="20"
                        value={formData.subject}
                        onChange={handleInputChange} // Set body state on change
                        placeholder="Type subject here..."
                        className=" p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7] h-36"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="measege"
                        className="text-[14px] text-center text-[#4D5464] font-medium tracking-[0.07px]"
                      >
                        Message
                      </label>
                      <div className="flex items-center">
                        <textarea
                          name="message"
                          id="message"
                          cols="30"
                          rows="20"
                          value={formData.message}
                          onChange={handleInputChange} // Set body state on change
                          placeholder="Type message here..."
                          className=" p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7] h-36"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-2 gap-4 justify-center">
                    <button
                      onClick={() => setShowModal(false)}
                      className="text-sm text-blue-500 font-semibold bg-blue-100 py-2 px-4 rounded-lg"
                    >
                      Cancel
                    </button>
                    <button
                      className="flex gap-1 rounded-lg items-center bg-blue-600 text-white py-2 px-4"
                      disabled={status === "loading"}
                      onClick={handleSubmit}
                    >
                      {status === "loading" ? (
                        <>
                          <FaSpinner className="animate-spin" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Sending ...
                          </p>
                        </>
                      ) : (
                        <>
                          <PiPlusBold className="w-4 h-4" />
                          <p className="text-sm font-semibold whitespace-nowrap">
                            Sending Auto Email Message
                          </p>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
