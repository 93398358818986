import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPaystackStatus,
  paywithPaystack,
} from "../redux/slices/paymentSlice";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";

const PaymentOptions = () => {
  const [selectedOption, setSelectedOption] = useState(""); // Tracks the selected payment option
  const [modalState, setModalState] = useState({
    isOpen: false,
    nextOption: null,
  }); // Handles modal visibility and next option state
  const [isLoading, setIsLoading] = useState(false); // Tracks loading state for confirmation

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPaystackStatus()); // Fetch initial payment status
  }, [dispatch]);

  const paystackStatus = useSelector(
    (state) => state.payment?.paystackStatuses
  );

  // console.log(paystackStatus);

  useEffect(() => {
    if (paystackStatus === "off") {
      setSelectedOption("manual");
    } else if (paystackStatus === "on") {
      setSelectedOption("paystack");
    }
  }, [paystackStatus]);

  // Determine the next payment option and open the confirmation modal
  const toggleOption = () => {
    const nextOption = selectedOption === "manual" ? "paystack" : "manual";
    setModalState({ isOpen: true, nextOption });
  };

  // Confirm the switch to the selected payment option
  const confirmToggle = async () => {
    setIsLoading(true);
    const { nextOption } = modalState;

    const res = await dispatch(paywithPaystack({ option: nextOption }));

    if (res.payload?.status === "success") {
      setSelectedOption(nextOption);
      toast.success(
        `Switched to ${nextOption === "paystack" ? "Paystack" : "Manual"} payment`
      );
    } else {
      toast.error(res.payload?.message || "An error occurred while switching");
    }

    setModalState({ isOpen: false, nextOption: null });
    setIsLoading(false);
  };

  // Close the modal without changing the option
  const cancelToggle = () => {
    setModalState({ isOpen: false, nextOption: null });
  };

  return (
    <div className="flex justify-between items-center">
      {/* Toggle Switch */}
      <div
        onClick={toggleOption}
        role="switch"
        aria-checked={selectedOption === "manual"}
        className={`relative flex justify-center items-center w-44 h-12 rounded-3xl border-2 cursor-pointer transition-all duration-500 
          ${selectedOption === "manual" ? "bg-green-500" : "bg-blue-300"} 
          border-gray-300`}
      >
        {/* Text for manual and paystack options */}
        {["manual", "paystack"].map((option) => (
          <div
            key={option}
            className={`absolute flex justify-between items-center w-full px-2 text-sm transition-opacity duration-500 ${
              selectedOption === option ? "opacity-100" : "opacity-0"
            }`}
          >
            <span
              className={option === "manual" ? "text-transparent" : "text-gray-50"}
            >
              Manual
            </span>
            <span
              className={option === "paystack" ? "text-transparent" : "text-gray-50"}
            >
              Paystack
            </span>
          </div>
        ))}

        {/* Slider Indicator */}
        <span
          className={`absolute transition-all duration-500 h-8 w-20 bg-white rounded-2xl font-semibold flex items-center justify-center
            ${selectedOption === "manual" ? "left-1" : "left-20"}`}
        >
          {selectedOption === "manual" ? "Manual" : "Paystack"}
        </span>
      </div>

      {/* Confirmation Modal */}
      {modalState.isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-80">
            <h2 className="text-lg font-semibold mb-4">Are you sure?</h2>
            <p className="mb-4">
              Do you want to switch to{" "}
              {modalState.nextOption === "manual" ? "Manual" : "Paystack"}?
            </p>
            <div className="flex justify-end">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={cancelToggle}
              >
                Cancel
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md flex items-center justify-center"
                onClick={confirmToggle}
                disabled={isLoading}
              >
                {isLoading ? (
                  <div className="flex items-center gap-2">
                    <ImSpinner2 className="animate-spin text-base text-gray-50" />
                    Confirming...
                  </div>
                ) : (
                  "Confirm"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentOptions;
