import React, { useState } from "react";
import { useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchProvince } from "../../redux/slices/provinceSlice";
import { formatCreatedAt } from "../../utils/commonUtils";
import ModalAddProvince from "../../components/modals/location/province/ModalAddProvince";

import { ImSpinner2 } from "react-icons/im";
import ModalUpdateProvince from "../../components/modals/location/province/ModalUpdateProvince";
import ModalDeleteProvince from "../../components/modals/location/province/ModalDeleteProvince";

const Province = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchProvince());
  }, [dispatch]);

  const provinces = useSelector((state) => state.province?.provinces);
  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));

  const status = useSelector((state) => state.province?.status);
  const error = useSelector((state) => state.province?.error);

  const getStateNameById = (state_id) => {
    const parsedId = parseInt(state_id, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? { name: state.name } : { name: "Unknown" };
  };

  const getCountryNameById = (country_id) => {
    const parsedId = parseInt(country_id, 10);

    const country = countries.find((country) => country.id === parsedId);

    return country ? { name: country.name } : { name: "Unknown" };
  };

  const reversedFilteredCity = provinces.slice().reverse();

  
  const filteredCities = reversedFilteredCity.filter((city) => {
    const cityName = city.name ? city.name.toLowerCase() : ""; // Handle undefined city.name
    const stateObj = getStateNameById(city.state_id); // Fetch the state object
    const stateName = stateObj && stateObj.name ? stateObj.name.toLowerCase() : ""; // Handle undefined state name
    const countryObj = getCountryNameById(city.country_id); // Fetch the country object
    const countryName = countryObj && countryObj.name ? countryObj.name.toLowerCase() : ""; // Handle undefined country name

    return search.toLowerCase() === ""
        ? city
        : cityName.includes(search.toLowerCase()) ||
          stateName.includes(search.toLowerCase()) ||
          countryName.includes(search.toLowerCase());
});


  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentCities = filteredCities.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredCities.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };


  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Cities
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Location</p>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">Cities</p>
          </div>
        </div>

      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
          <input
            type="text"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
            placeholder="Search city, state, country..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </button>

        <div className="flex items-center gap-4">
          <ModalAddProvince />
        </div>
      </div>
      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="w-full text-gray-700 bg-gray-50">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-3 text-center">City</th>
                <th className="px-4 py-3 text-center">State</th>
                <th className="px-4 py-3 text-center">Country</th>

                <th className="px-4 py-3 text-center">Added</th>
                <th className="px-4 py-3 text-center">Action</th>
              </tr>
            </thead>

            <tbody className="w-fit">
            {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentCities.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentCities?.map((province, index) => (
                  <tr key={province.id} className="bg-white border-b">
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p className="text-[14px] p-2 text-[#333843] font-medium">
                        {province.name}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p className="text-[14px] p-2 text-[#333843] font-medium">
                        {getStateNameById(province.state_id).name}
                      </p>
                    </td>
                    <td className="px-4 py-4 text-center">
                      <p className="text-[14px] p-2 text-[#333843] font-medium">
                        {getCountryNameById(province.country_id).name}
                      </p>
                    </td>

                    <td className="px-2 py-2 text-center">
                      {formatCreatedAt(province.created_at)}
                    </td>

                    <td className="px-2 py-2">
                      <div className="flex gap-4 justify-center">
                        <div className="mr-2">
                          <ModalUpdateProvince
                            id={province.id}
                            name={province.name}
                            country_id={province.country_id}
                            state_id={province.state_id}
                          />
                        </div>
                        <div>
                          <ModalDeleteProvince
                            id={province.id}
                            name={province.name}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {filteredCities.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Province;
