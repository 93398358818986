import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiService } from "../../services/apiService";

export const fetchApprovedTradefair = createAsyncThunk(
  "tradefair/fetchApprovedTradefair",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/approved-tradefair", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTradefairById = createAsyncThunk(
  'tradefair/fetchTradefairById',
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await apiService.get(`/view-tradefair/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchPendingTradefair = createAsyncThunk(
  "tradefair/fetchPendingTradefair",
  async (_, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.get("/pending-tradefair", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const approveTradefair = createAsyncThunk(
  "tradefair/approveTradefair",
  async (tradefairId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await apiService.post(
        `/approve-tradefair/${tradefairId}`,
       
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const tradefairsSlice = createSlice({
  name: "tradefair",
  initialState: {
    approvedTradefairs: [],
    pendingTradefairs: [],
    tradefairDetails: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchApprovedTradefair.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchApprovedTradefair.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.approvedTradefairs = action.payload.data;
      })
      .addCase(fetchApprovedTradefair.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPendingTradefair.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPendingTradefair.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.pendingTradefairs = action.payload.data;
      })
      .addCase(fetchPendingTradefair.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTradefairById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(fetchTradefairById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tradefairDetails = action.payload.data;
      })
      .addCase(fetchTradefairById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(approveTradefair.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveTradefair.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.error = null;
      })
      .addCase(approveTradefair.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default tradefairsSlice.reducer;
