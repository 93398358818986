import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchAdmins, updateAdmin } from "../../redux/slices/adminSlice";
import { FaPlus } from "react-icons/fa";
import { CgSpinner } from "react-icons/cg";
import Accordion from "../../components/Accordion";

const AdminFunctions = () => {
  // const [admins, setAdmins] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    role: "",
    functions: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdmins());
  }, [dispatch]);

  const adminLists = useSelector((state) => state.admin?.admins);
  const status = useSelector((state) => state.admin?.status);
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const currentAdminId = storedUser?.id; // Assuming the ID is stored under 'id'

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Find the selected admin
    const selectedAdmin = adminLists.find((admin) => admin.name === value);

    const selectedAdminRole = selectedAdmin ? selectedAdmin.role : "";

    // Update the formData state when Admin name is selected
    setFormData({
      ...formData,
      [name]: value,
      name: selectedAdmin?.name,
      email: selectedAdmin?.email,
      phone: selectedAdmin?.phone,
      country: selectedAdmin?.country,
      state: selectedAdmin?.state,
      city: selectedAdmin?.city,
      role: selectedAdminRole,
      user_id: selectedAdmin?.id,
      functions: selectedAdmin?.functions || [],
    });
  };

  const handleAddAdmin = async () => {
    // Add new admin without generating an ID
    // setAdmins((prevAdmins) => [...prevAdmins, formData]);

    // console.log(formData);

    try {
      const res = await dispatch(updateAdmin(formData));

      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }

    setFormData({
      name: "",
      role: "",
      functions: [],
    });
  };

  return (
    <div className="p-4">
      <div className="flex justify-between mb-4">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Assign Admin Functions
          </h1>
          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>
            <HiChevronRight className="text-gray-500" />
            <p className="text-[14px] text-blue-500 font-medium">Admin</p>
            <HiChevronRight className="text-gray-500" />
            <p className="text-[14px] text-gray-500 font-medium">
              Assign Admin Functions
            </p>
          </div>
        </div>
        <div className="flex items-end">
          <button
            onClick={handleAddAdmin}
            disabled={formData.functions.length === 0}
            className={`py-2 px-4 text-[14px] flex justify-center items-center gap-2 rounded-md font-medium text-white ${
              formData.functions.length === 0
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-[#1F7BF4] hover:shadow-lg"
            }`}
          >
            {status === "loading" ? (
              <>
                <CgSpinner className="animate-spin text-lg " />
                Saving Admin & Functions...
              </>
            ) : (
              <>
                <FaPlus className=" text-lg font-extrabold" />
                Add Admin & Functions
              </>
            )}
          </button>
        </div>
      </div>

      <div className="mt-4 mb-4">
        <div className="flex gap-4 mb-2">
          <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="name"
                className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
              >
                Admin Name
              </label>
              <div className="flex items-center">
                <select
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                >
                  <option value="" className="text-[#333843] " disabled>
                    Select Admin
                  </option>
                  {adminLists
                    ?.filter((admin) => admin.id !== currentAdminId)
                    .map((admin) => (
                      <option key={admin.id} value={admin.name}>
                        {admin.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>

          <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="role"
                className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]"
              >
                Role
              </label>
              <input
                type="text"
                name="role"
                id="role"
                value={formData.role}
                onChange={handleInputChange}
                className="px-3 py-3 text-[#333843] text-[14px] rounded-lg bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                disabled
              />
            </div>
          </div>
        </div>

        <Accordion formData={formData} setFormData={setFormData} />
      </div>
    </div>
  );
};

export default AdminFunctions;
