import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

import {
  fetchFastFoodDetail,
  selectFastFoodDetail,
} from "../../redux/slices/fastFoodSlice";

import AddProductFood from "../../components/modals/product/AddProductFood";
import { ImSpinner2 } from "react-icons/im";
import DeleteProductFood from "../../components/modals/product/DeleteProductFood";
import UpdateProductFood from "../../components/modals/product/UpdateProductFood";
import FoodProductDetail from "./FoodProductDetail";
import TransferProductFastFood from "../../components/modals/product/TransferProductFastFood";
import FoodIncrementPrice from "../../components/modals/product/FoodIncrementPrice";
import FoodDiscountPrice from "../../components/modals/product/FoodDiscountPrice";
import { allProduct } from "../../redux/slices/productSlice";
import InStockFF from "../../components/modals/product/InStockFF";
import OutOfStockFF from "../../components/modals/product/OutOfStockFF";

const FastFoodDetail = () => {
  const { id } = useParams();

  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentPage, setCurrentPage] = useState(
    parseInt(searchParams.get("page")) || 1
  );
  useEffect(() => {
    const page = parseInt(searchParams.get("page")) || 1;
    setCurrentPage(page);
  }, [searchParams]);

  const dispatch = useDispatch();

  const fastFoodDetail = useSelector(selectFastFoodDetail);

  const owner_type = "food";

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  useEffect(() => {
    dispatch(fetchFastFoodDetail({ id, page: currentPage }));
    dispatch(allProduct({ id, owner_type }));
  }, [dispatch, id, currentPage]);

  const allProducts = useSelector((state) => state.product?.allProducts);

  const filteredProducts = allProducts?.filter((product) => {
    const searchTerm = search.trim().toLowerCase(); // Normalize search input
    return searchTerm === "" || product?.name?.toLowerCase().includes(searchTerm);
  });
  

  const itemsPerPage = 14; // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentProductData = filteredProducts.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    if (currentPage < totalPages) {
      setSearchParams({ page: currentPage + 1 });
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setSearchParams({ page: currentPage - 1 });
    }
  };

  const status = useSelector((state) => state.fastFood?.fastFoodDetailStatus);
  const statusp = useSelector((state) => state.product?.status);
  const error = useSelector((state) => state.fastFood?.error);

  const getCountryNameById = (countryId) => {
    const parsedId = parseInt(countryId, 10);
    const country = countries.find((country) => country.id === parsedId);

    return country ? country.name : "Unknown";
  };

  const getStateNameById = (stateId) => {
    const parsedId = parseInt(stateId, 10);

    const state = states.find((state) => state.id === parsedId);

    return state ? state.name : "Unknown";
  };

  const getCityNameById = (cityId) => {
    const parsedId = parseInt(cityId, 10);
    const city = provinces.find((city) => city.id === parsedId);

    return city ? city.name : "Unknown";
  };

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, productId) => {
    const isChecked = e.target.checked;

    setSelectedProductIds((prevSelectedIds) => {
      if (isChecked) {
        // If checkbox is checked, add the productId to the selectedProductIds array
        return [...prevSelectedIds, productId];
      } else {
        // If checkbox is unchecked, remove the productId from the selectedProductIds array
        return prevSelectedIds.filter((id) => id !== productId);
      }
    });
  };

  const handleHeaderCheckboxChange = (e) => {
    const isChecked = e.target.checked;

    setSelectAll(isChecked);

    if (isChecked) {
      const allProductIds = allProducts?.map((product) => product.id);
      setSelectedProductIds(allProductIds);
    } else {
      setSelectedProductIds([]);
    }
  };

  const clearSelectedProductIds = () => {
    setSelectedProductIds([]);
    setSelectAll(false);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Fast Food Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#1F7BF4]  font-medium">Shopping</p>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/shopping/fast-food">
              <p className="text-[14px] text-[#1F7BF4] font-medium">
                Fast Food
              </p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Fast Food Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {fastFoodDetail ? (
            <>
              <div className="flex my-8 gap-6">
                <div className="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                        Fast Food Information
                      </h1>

                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] whitespace-nowrap font-medium tracking-[0.07px]">
                              Name
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {fastFoodDetail?.data?.Food?.name}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Landmark
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {fastFoodDetail?.data?.Food?.landmark}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Location
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {fastFoodDetail?.data?.Food?.location}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Status
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {fastFoodDetail?.data?.Food?.type}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Start Date
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {formatCreatedAt(
                              fastFoodDetail?.data?.Food?.created_at
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Updated Date
                            </p>
                          </div>
                          <p className=" text-[#1A1C21] text-base font-semibold tracking-[0.07px]">
                            {formatCreatedAt(
                              fastFoodDetail?.data?.Food?.updated_at
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Country
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {getCountryNameById(
                              fastFoodDetail.data.Food.country
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              State
                            </p>
                          </div>

                          <p className="text-base font-semibold whitespace-nowrap">
                            {getStateNameById(fastFoodDetail.data.Food.state)}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              City
                            </p>
                          </div>
                          <p className="text-base font-semibold whitespace-nowrap">
                            {getCityNameById(fastFoodDetail.data.Food.province)}
                          </p>
                        </div>

                        <div className="flex justify-between gap-4">
                          <div className="flex gap-2 items-center">
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Note
                            </p>
                          </div>
                          <textarea
                            name=""
                            id=""
                            cols="10"
                            rows="5"
                            readOnly
                            value={fastFoodDetail.data.Food.note}
                            className=" w-1/2 text-[#1A1C21] text-base border-2 rounded-lg p-2 tracking-[0.07px]"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className=" flex flex-col  gap-6 w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                  <div className="flex flex-col w-full gap-[24px] h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className=" flex justify-between">
                      <div className="flex justify-between items-center gap-2">
                        <h1 className="text-[18px] text-[#1A1C21] font-semibold tracking-[0.09px]">
                          Products
                        </h1>
                        <button className="relative w-72">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <HiOutlineSearch className=" text-gray-500 text-2xl" />
                          </div>
                          <input
                            type="text"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
                            placeholder="Search product..."
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </button>
                      </div>
                      <div className=" flex justify-between items-center gap-4">
                        <AddProductFood
                          foodId={id}
                          country={fastFoodDetail.data.Food.country}
                          city={fastFoodDetail.data.Food.province}
                          state={fastFoodDetail.data.Food.state}
                          page={currentPage}
                        />
                        {allProducts?.length === 0 ? (
                          ""
                        ) : (
                          <TransferProductFastFood
                            owner_id={id}
                            page={currentPage}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                            country={fastFoodDetail.data.Food.country}
                            city={fastFoodDetail.data.Food.province}
                            state={fastFoodDetail.data.Food.state}
                          />
                        )}
                        {allProducts?.length === 0 ? (
                          ""
                        ) : (
                          <FoodDiscountPrice
                            foodId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                            page={currentPage}
                          />
                        )}

                        {allProducts?.length === 0 ? (
                          ""
                        ) : (
                          <FoodIncrementPrice
                            foodId={id}
                            clearSelectedProductIds={clearSelectedProductIds}
                            selectedProductIds={selectedProductIds}
                            page={currentPage}
                          />
                        )}
                      </div>
                    </div>
                    <div className="relative overflow-x-auto  p-4 rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500">
                        <thead className="text-sm text-gray-700 whitespace-nowrap bg-gray-50">
                          <tr>
                            <th className="px-4 py-4 text-center">No.</th>
                            <th className="px-12 py-4 text-center">Image</th>
                            <th className="px-4 py-4 text-center">Name</th>

                            <th className="px-4 py-4 text-center">Category</th>
                            <th className="px-4 py-4 text-center">
                              Description
                            </th>
                            <th className="px-4 py-4 text-center ">Type</th>
                            <th className="px-4 py-4 text-center ">Price</th>

                            <th className="px-4 py-4 text-center">Status</th>
                            <th className="px-8 py-4 text-center">Created</th>
                            <th className="px-8 py-4 text-center">Updated</th>
                            <th className="px-2 py-4 text-center">
                              <div className=" flex justify-center items-center gap-2">
                                <h1>Action</h1>
                                {allProducts?.length === 0 ? (
                                  ""
                                ) : (
                                  <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleHeaderCheckboxChange}
                                    className="w-4 h-4"
                                  />
                                )}
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-sm">
                          {statusp === "loading" ? (
                            <tr>
                              <td colSpan="12" className="text-center py-4">
                                <div className="flex justify-center items-center">
                                  <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                                </div>
                              </td>
                            </tr>
                          ) : currentProductData?.length === 0 ? (
                            <tr>
                              <td
                                colSpan="12"
                                className="text-center text-gray-500 py-4"
                              >
                                No Data
                              </td>
                            </tr>
                          ) : (
                            currentProductData?.map((product, index) => (
                              <tr key={index} className="bg-white border-b">
                                <td className="px-4 py-4">
                                  <div className="flex justify-center items-center">
                                    <p className=" text-base text-center font-semibold ">
                                      {indexOfFirstItem + index + 1}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-2 py-2">
                                  <div className="flex justify-center p-2 items-center">
                                    <img
                                      src={
                                        product.image &&
                                        product.image.length > 0
                                          ? product.image[0]
                                          : "default-image-url.jpg"
                                      }
                                      alt="product"
                                      className="w-24 h-24 object-cover rounded-lg"
                                    />
                                  </div>
                                </td>
                                <td className="px-4 py-4">
                                  <div className="p-2">
                                    <p className="text-gray-500 text-center w-48 break-words ">
                                      {product.name}
                                    </p>
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {product.category}
                                </td>
                                <td className="px-14 py-4">
                                  <p className="w-48 break-words text-center">
                                    {product.description
                                      ? product.description
                                      : "No description"}
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <p className="m-2">{product.type}</p>
                                </td>

                                <td className="px-4 py-4 text-center">
                                  <p>
                                    <span className="mr-1">&#8358;</span>
                                    {formatNumberWithCommas(product.price)}
                                  </p>
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                <div className=" flex justify-center">
                                    {product.out_of_stock === "1" ? (
                                      <InStockFF
                                        name={product.name}
                                        pId={product.id}
                                        id={id}
                                        page={currentPage}
                                      />
                                    ) : (
                                      <OutOfStockFF
                                        name={product.name}
                                        pId={product.id}
                                        id={id}
                                        page={currentPage}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.created_at)}
                                </td>
                                <td className="px-4 py-4 text-center whitespace-nowrap">
                                  {formatCreatedAt(product.updated_at)}
                                </td>
                                <td className="px-4 py-4 text-center">
                                  <div className="flex gap-[8px] justify-center items-center">
                                    <div>
                                      <FoodProductDetail
                                        images={product.image}
                                        category={product.category}
                                        name={product.name}
                                        color={product.color}
                                        size={product.size}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        country={getCountryNameById(
                                          product.country
                                        )}
                                        city={getCityNameById(product.city)}
                                        state={getStateNameById(product.state)}
                                        created_at={formatCreatedAt(
                                          product.created_at
                                        )}
                                        updated_at={formatCreatedAt(
                                          product.updated_at
                                        )}
                                      />
                                    </div>
                                    <div>
                                      <UpdateProductFood
                                        id={id}
                                        page={currentPage}
                                        pId={product.id}
                                        image={product.image}
                                        category={product.category}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                        country={product.country}
                                        city={product.city}
                                        state={product.state}
                                      />
                                    </div>
                                    <div>
                                      <DeleteProductFood
                                        id={id}
                                        pId={product.id}
                                        image={product.image}
                                        category={product.category}
                                        name={product.name}
                                        description={product.description}
                                        price={product.price}
                                        type={product.type}
                                      />
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        value={product.id}
                                        onChange={(e) =>
                                          handleCheckboxChange(e, product.id)
                                        }
                                        className="w-4 h-4"
                                        checked={selectedProductIds.includes(
                                          product.id
                                        )}
                                      />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="flex justify-between px-[24px] py-[15px] items-center">
                      <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
                        Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
                        {filteredProducts?.length}
                      </p>

                      <div className="flex gap-[8px]">
                        <button
                          onClick={prevPage}
                          disabled={currentPage === 1}
                          className={`px-4 py-2 rounded-md ${
                            currentPage === 1
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                        >
                          Previous
                        </button>

                        <button
                          onClick={nextPage}
                          disabled={currentPage === totalPages}
                          className={`px-4 py-2 rounded-md ${
                            currentPage === totalPages
                              ? "bg-gray-300 cursor-not-allowed"
                              : "bg-blue-500 hover:bg-blue-600"
                          }`}
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
          )}
        </div>
      )}
    </div>
  );
};

export default FastFoodDetail;
