import React, { useState } from "react";
import { FaSpinner, FaTimes } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { BsExclamationLg } from "react-icons/bs";

import { FaRegSquareCheck } from "react-icons/fa6";

import { closeFastFood, fetchFastFood } from "../../../redux/slices/fastFoodSlice";

export default function OpenFastFood({ id, name }) {
  const [showModal, setShowModal] = useState(false);

  const status = useSelector((state) => state.fastFood?.status);
  const dispatch = useDispatch();

  const handleClose = async (e) => {
    e.preventDefault();

    try {
      const res = await dispatch(closeFastFood(id));

      await dispatch(fetchFastFood());
      toast.success(res.payload.message);
    } catch (error) {
      console.error(error);
    }

    setShowModal(false);
  };

  return (
    <>
      <div className=" flex items-center">
        <button
          type="button"
          onClick={() => setShowModal(true)}
          className="font-medium flex gap-2"
        >
          <FaRegSquareCheck className="text-green-600 text-lg" /> Open
        </button>
      </div>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-5xl">
              <div className="border-0 rounded-lg p-6 shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                <button
                  type="button"
                  onClick={() => setShowModal(false)}
                  className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center"
                >
                  <FaTimes className="text-gray-300 text-xl" />
                  <span className="sr-only">Close modal</span>
                </button>
                <div className=" text-center mb-4">
                  <h1 className="text-2xl text-gray-800 text-center font-medium">
                    Open Market
                  </h1>
                  <p>Do you want to Open this market</p>
                </div>
                <div className="flex justify-center items-center m-2">
                  <BsExclamationLg className="text-gray-500 text-3xl font-bold" />
                </div>
                <div className="flex items-center px-8 py-4 gap-6">
                  <div className="flex flex-col gap-4">
                    <div className="flex flex-row gap-2 justify-center items-center">
                      <h1 className="text-[14px] text-left text-gray-400 ">
                        name :
                      </h1>
                      <div className="">
                        <p className=" text-base font-bold">{name}</p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-1">
                      <div className="flex items-center">
                        <input
                          name="id"
                          type="hidden"
                          id="id"
                          value={id}
                          // onChange={handleChange}
                          className="pl-[12px] text-[#333843] p-2 rounded-lg text-[14px] bg-[#F9F9FC] w-full border-2 border-[#E0E2E7]"
                        />
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <button
                        onClick={() => setShowModal(false)}
                        className="text-sm text-gray-500 font-semibold bg-gray-100 py-2 px-4 rounded-md"
                      >
                        Cancel
                      </button>
                      <button
                        className="flex gap-1 rounded-md items-center bg-green-600 text-white py-2 px-4"
                        disabled={status === "loading"}
                        onClick={handleClose}
                      >
                        {status === "loading" ? (
                          <>
                            <FaSpinner className="animate-spin" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Opening...
                            </p>
                          </>
                        ) : (
                          <>
                            <FaTimes className="w-4 h-4" />
                            <p className="text-sm font-semibold whitespace-nowrap">
                              Open Market
                            </p>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
