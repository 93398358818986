import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { fetchVendorById } from "../../redux/slices/vendorsSlice";
import { MdDateRange } from "react-icons/md";
import { HiChevronRight } from "react-icons/hi";
import { ImSpinner2 } from "react-icons/im";
import { IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";
import { IoHomeOutline, IoLocationOutline } from "react-icons/io5";
import { FaCity, FaFileCode } from "react-icons/fa";
import { TbNumber } from "react-icons/tb";
import {
  formatCreatedAt,
  formatNumberWithCommas,
} from "../../utils/commonUtils";

const VendorDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { vendorDetails, status, error } = useSelector((state) => state.vendor);

  useEffect(() => {
    dispatch(fetchVendorById(id));
  }, [dispatch, id]);

  const vendor = vendorDetails?.data?.producer;
  const products = vendorDetails?.data?.products;

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const getCountryNameFromId = (countryId) => {
    const country = countries?.find(
      (country) => country.id === parseInt(countryId, 10)
    );
    return country ? country.name : "No country";
  };

  const getStateNameFromId = (stateId) => {
    const state = states?.find((state) => state.id === parseInt(stateId, 10));
    return state ? state.name : "No state";
  };

  const getCityNameFromId = (provinceId) => {
    const province = provinces?.find(
      (province) => province.id === parseInt(provinceId, 10)
    );
    return province ? province.name : "No city";
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Vendor Details
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to="/dashboard/vendor/vendors">
              <p className="text-[14px] text-[#1F7BF4]  font-medium">Vendors</p>
            </Link>
            <HiChevronRight className=" text-gray-500" />

            <p className="text-[14px] text-[#667085] font-medium">
              Vendor Details
            </p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="12" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <div className="space-y-4">
          <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <div className="h-8 bg-gray-300 rounded w-1/3 skeleton"></div>
            <div className="flex flex-col md:flex-row items-center mt-4">
              <div className="mb-4 md:mb-0 md:mr-4 w-full">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Column 1
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Column 2
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Column 3
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Column 4
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="h-4 bg-gray-300 rounded w-1/2 skeleton mb-2"></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="h-4 bg-gray-300 rounded w-1/3 skeleton mb-2"></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="h-4 bg-gray-300 rounded w-1/4 skeleton mb-2"></div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="h-4 bg-gray-300 rounded w-1/4 skeleton mb-2"></div>
                      </td>
                    </tr>
                    <tr>
                      <td className="px-6 py-4 whitespace-nowrap" colSpan="4">
                        <div className="h-24 bg-gray-300 rounded skeleton"></div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="flex justify-center items-center w-full md:w-auto">
                <div className="w-64 h-64 bg-gray-300 rounded-lg skeleton"></div>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {vendor && (
            <div>
              <div className="flex flex-row-reverse mt-4">
                <div className="w-6/12 pl-4">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between gap-2">
                          <p className="text-base text-gray-700  font-medium">
                            Wallet
                          </p>
                          <div>
                            <p className="text-3xl mt-4 text-start text-[#257443] font-bold">
                              <span className="mr-1">&#8358;</span>
                              {formatNumberWithCommas(
                                vendorDetails?.data?.wallet_balance
                                  ? vendorDetails?.data?.wallet_balance
                                  : "0"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex flex-col gap-6 w-6/12">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                        Vendor Information
                      </h1>

                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdPerson size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Name
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.name}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdMail size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Email
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.email}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdCall size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              Phone
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.phone}
                          </p>
                        </div>

                        <div className="flex justify-between items-center gap-2">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoHomeOutline size={20} color="#667085" />
                              </div>
                            </div>

                            <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                              Address
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] text-right break-words w-48 font-medium tracking-[0.07px]">
                            {vendor.address ? vendor.address : "No Info"}
                          </p>
                        </div>

                        <div className="flex justify-between items-center gap-2">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaFileCode size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                              Ref Code
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.ref_code}
                          </p>
                        </div>
                        <div className="flex justify-between items-center gap-2">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <TbNumber size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-[14px] text-[#4D5464] font-medium tracking-[0.07px]">
                              No. of Referrals
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.referrals
                              ? vendor.referrals.length
                              : "No Info"}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaCity size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                              City
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.city
                              ? getCityNameFromId(vendor.city)
                              : "No Info"}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                              State
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.state
                              ? getStateNameFromId(vendor.state)
                              : "No Info"}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                              Country
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {vendor.country
                              ? getCountryNameFromId(vendor.country)
                              : "No info"}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <MdDateRange size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                                Created
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {formatCreatedAt(vendor.created_at)}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <MdDateRange size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-sm text-[#1A1C21] font-medium tracking-[0.07px]">
                                Updated
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {formatCreatedAt(vendor.updated_at)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-[24px] w-full h-auto mt-8 px-6 py-6 bg-white rounded-lg shadow">
                <h3 className="text-lg font-semibold mb-4">Products</h3>
                <div className="relative overflow-x-auto border-[1px]rounded-lg border-[#E8E8E8]">
                  <table className="w-full text-sm">
                    <thead className="bg-gray-100">
                      <tr className="border-b text-center">
                        <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                          No.
                        </th>
                        <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                          Name
                        </th>

                        <th className="px-8 py-4 text-center">Image</th>
                        <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                          Description
                        </th>
                        <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                          Price
                        </th>
                        <th className="px-4 py-4 text-center">Country</th>
                        <th className="px-4 py-4 text-center">State</th>
                        <th className="px-4 py-4 text-center">City</th>
                        {/* <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                        Type
                      </th> */}
                        <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                          Created
                        </th>
                        {/* <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                        Time
                      </th> */}
                        <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                          Updated
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {status === "failed" ? (
                        <tr>
                          <td colSpan="12" className="text-center py-4">
                            <div className="flex justify-center items-center">
                              Error. {error}.{" "}
                              <span className="text-base font-semibold ">
                                {" "}
                                Try again later
                              </span>
                            </div>
                          </td>
                        </tr>
                      ) : status === "loading" ? (
                        <tr>
                          <td colSpan="12" className="text-center py-4">
                            <div className="m-auto">
                              <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                            </div>
                          </td>
                        </tr>
                      ) : products?.length === 0 ? (
                        <tr>
                          <td colSpan="12" className="text-center py-4">
                            No data
                          </td>
                        </tr>
                      ) : (
                        products?.map((product, index) => (
                          <tr key={product.id} className="border-b text-center">
                            <td className="px-4 py-2 text-sm text-[#1A1C21]">
                              {index + 1}
                            </td>
                            <td className="px-4 py-2 text-sm text-[#1A1C21]">
                              {product.name}
                            </td>
                            <td className="px-4 py-4 text-center">
                              <img
                                src={product.image[0]}
                                alt={product.name}
                                className="w-16 h-16 object-cover rounded-lg mx-auto"
                              />
                            </td>
                            <td className="px-4 py-2 text-sm text-[#1A1C21]">
                              {product.description}
                            </td>
                            <td className="px-4 py-2 text-sm text-[#1A1C21]">
                              <span className="mr-1">&#8358;</span>
                              {formatNumberWithCommas(product.price || "0")}
                            </td>
                            <td className="px-4 py-4 text-center whitespace-nowrap">
                              {getCountryNameFromId(product.country)}
                            </td>
                            <td className="px-4 py-4 text-center whitespace-nowrap">
                              {getStateNameFromId(product.state)}
                            </td>
                            <td className="px-4 py-4 text-center whitespace-nowrap">
                              {getCityNameFromId(product.city)}
                            </td>
                            {/* <td className="px-4 py-2 text-sm text-[#1A1C21]">
                            <span
                              className={`px-2 py-1 rounded-full text-white ${
                                product.transaction_type === "credit"
                                  ? "bg-green-500"
                                  : "bg-red-500"
                              }`}
                            >
                              {product.transaction_type === "credit"
                                ? "Credit"
                                : "Debit"}
                            </span>
                          </td> */}
                            <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                              {formatCreatedAt(product.created_at)}
                            </td>
                            {/* <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                            {formatTime(product.created_at)}
                          </td> */}
                            <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                              {formatCreatedAt(product.updated_at)}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VendorDetail;
