import React, { useEffect, useState } from "react";

import { HiChevronRight } from "react-icons/hi";
import { IoMdCall, IoMdMail, IoMdPerson } from "react-icons/io";

import { Link, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchDriverDetail,
  selectDriverDetail,
} from "../../redux/slices/driverSlice";
import { ImSpinner2 } from "react-icons/im";
import {
  convertValue,
  formatCreatedAt,
  formatNumberWithCommas,
  formatTime,
} from "../../utils/commonUtils";
import {
  FaAddressCard,
  FaCity,
  FaCreditCard,
  FaFileCode,
} from "react-icons/fa";
import ImageFullScreen from "../../components/ImageFullScreen";
import { VscReferences } from "react-icons/vsc";
import { IoLocationOutline } from "react-icons/io5";
import { MdDateRange, MdOutlineDomainVerification } from "react-icons/md";
import { BsBank2 } from "react-icons/bs";
import ModalSuspendDriver from "../../components/staffs/ModalSuspendDriver";
import { TbNumber } from "react-icons/tb";

const DriverDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filterType, setFilterType] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    dispatch(fetchDriverDetail(id));
  }, [dispatch, id]);

  const driverDetail = useSelector(selectDriverDetail);

  const status = useSelector((state) => state.driver?.driverDetailStatus);
  const error = useSelector((state) => state.driver?.error);

  const countries = JSON.parse(localStorage.getItem("country"));
  const states = JSON.parse(localStorage.getItem("state"));
  const provinces = JSON.parse(localStorage.getItem("province"));

  const filteredTransactions = driverDetail?.data?.transactions?.filter(
    (transaction) =>
      filterType === "" ||
      transaction.transaction_type.toLowerCase() === filterType.toLowerCase()
  );

  const getCountryNameFromId = (countryId) => {
    const country = countries?.find(
      (country) => country.id === parseInt(countryId, 10)
    );
    return country ? country.name : "Unknown";
  };

  const getStateNameFromId = (stateId) => {
    const state = states?.find((state) => state.id === parseInt(stateId, 10));
    return state ? state.name : "Unknown";
  };

  const getCityNameFromId = (cityId) => {
    const city = provinces?.find((city) => city.id === parseInt(cityId, 10));
    return city ? city.name : "Unknown";
  };

  return (
    <div className="p-4">
      <div className="flex justify-between items-center">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Driver Detail
          </h1>

          <div className="flex justify-center items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <Link to={"/dashboard/drivers"}>
              <p className="text-[14px] text-[#1F7BF4]  font-medium">
                All Driver
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-[#667085] font-medium">
              Driver Detail
            </p>
          </div>
        </div>
        <div className="mt-12">
          <div className="flex gap-2 border-2 p-2 rounded-3xl cursor-pointer">
            <ModalSuspendDriver
              id={driverDetail?.data?.driver?.id}
              name={driverDetail?.data?.driver?.name}
            />
            <p>Suspend</p>
          </div>
        </div>
      </div>

      {status === "failed" ? (
        <tr>
          <td colSpan="8" className="text-center py-4">
            <div className="flex justify-center items-center">
              Error. {error}.{" "}
              <span className="text-base font-semibold "> Try again later</span>
            </div>
          </td>
        </tr>
      ) : status === "loading" ? (
        <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
      ) : (
        <div>
          {driverDetail ? (
            <div className="flex flex-col">
              <div className="flex mt-8 gap-6">
                <div className="flex flex-col gap-6 w-1/2">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px]">
                        Driver Information
                      </h1>

                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdPerson size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Name
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.name}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdMail size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Email
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.email}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdCall size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Phone
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.phone}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaAddressCard size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Address
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] w-48 break-words font-medium text-right">
                            {driverDetail?.data?.driver?.address
                              ? driverDetail?.data?.driver?.address
                              : "No Info"}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <VscReferences size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Referred By
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.referred_by
                              ? driverDetail?.data?.driver?.referred_by
                              : "No Info"}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaFileCode size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Ref Code
                            </p>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.ref_code}
                          </p>
                        </div>
                        <div className="flex justify-between items-center gap-2">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <TbNumber size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              No. of Referrals
                            </p>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.referrals.length}
                          </p>
                        </div>
                        <div className="flex flex-col gap-[14px]">
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <FaCity size={20} color="#667085" />
                                </div>
                              </div>
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                City
                              </p>
                            </div>
                            <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              {getCityNameFromId(
                                driverDetail?.data?.driver?.city
                              )}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoLocationOutline
                                    size={20}
                                    color="#667085"
                                  />
                                </div>
                              </div>
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                State
                              </p>
                            </div>
                            <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              {getStateNameFromId(
                                driverDetail?.data?.driver?.state
                              )}
                            </p>
                          </div>

                          <div className="flex justify-between items-center">
                            <div className="flex gap-2 items-center">
                              <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                                <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                  <IoLocationOutline
                                    size={20}
                                    color="#667085"
                                  />
                                </div>
                              </div>
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Country
                              </p>
                            </div>
                            <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                              {getCountryNameFromId(
                                driverDetail?.data?.driver?.country
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <MdOutlineDomainVerification
                                  size={20}
                                  color="#667085"
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Verified
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {convertValue(driverDetail?.data?.driver?.verified)}
                          </p>
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <MdOutlineDomainVerification
                                  size={20}
                                  color="#667085"
                                />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Suspend
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {convertValue(
                              driverDetail?.data?.driver?.suspended
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <MdDateRange size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Created
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {formatCreatedAt(
                              driverDetail?.data?.driver?.created_at
                            )}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <MdDateRange size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Last Updated
                              </p>
                            </div>
                          </div>
                          <p className="text-[14px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {formatCreatedAt(
                              driverDetail?.data?.driver?.updated_at
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col gap-6 w-1/2">
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between gap-2">
                          <p className="text-base text-gray-700  font-medium">
                            Wallet
                          </p>
                          <div>
                            <p className="text-3xl mt-4 text-start text-[#257443] font-bold">
                              <span className="mr-1">&#8358;</span>
                              {formatNumberWithCommas(
                                driverDetail?.data?.wallet
                                  ? driverDetail?.data?.wallet
                                  : "0"
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between gap-2">
                          <p className="text-base text-gray-700  font-medium">
                            Referee
                          </p>
                          <div>
                            {driverDetail?.data?.driver?.referee ? (
                              JSON.parse(
                                driverDetail?.data?.driver?.referee
                              ).map((refereeInfo, index) => (
                                <p
                                  key={index}
                                  className="text-[16px] text-right mt-2 text-[#1A1C21] font-medium"
                                >
                                  {refereeInfo}
                                </p>
                              ))
                            ) : (
                              <p className="text-[14px] mt-2 text-[#1A1C21] font-medium tracking-[0.07px]">
                                No Info
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-[24px] w-full h-auto px-6 py-6 bg-white rounded-lg shadow">
                    <div className="flex flex-col w-full gap-8 h-auto px-6 py-6 bg-white rounded-lg shadow">
                      <h1 className="text-base text-[#1A1C21] font-medium tracking-[0.09px]">
                        Documents
                      </h1>

                      <div className="flex flex-col gap-[14px]">
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <BsBank2 size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Bank
                              </p>
                            </div>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.bank
                              ? driverDetail?.data?.driver?.bank
                              : "No Info"}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaCreditCard size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                Account Number
                              </p>
                            </div>
                          </div>
                          <p className="text-[16px] text-[#1A1C21] font-medium tracking-[0.07px]">
                            {driverDetail?.data?.driver?.account_number
                              ? driverDetail?.data?.driver?.account_number
                              : "No Info"}
                          </p>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <FaAddressCard size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Address Verification
                            </p>
                          </div>
                          {driverDetail?.data?.driver?.address_verification ? (
                            <ImageFullScreen
                              src={
                                driverDetail?.data?.driver?.address_verification
                              }
                              alt={driverDetail?.data?.driver?.name}
                            />
                          ) : (
                            "No Image"
                          )}
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoMdPerson size={20} color="#667085" />
                              </div>
                            </div>
                            <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                              Passport
                            </p>
                          </div>
                          {driverDetail?.data?.driver?.passport ? (
                            <ImageFullScreen
                              src={driverDetail?.data?.driver?.passport}
                              alt={driverDetail?.data?.driver?.name}
                            />
                          ) : (
                            "No image"
                          )}
                        </div>

                        <div className="flex justify-between items-center">
                          <div className="flex gap-2 items-center">
                            <div className="bg-[#F0F1F3] w-[40px] h-[40px] flex items-center justify-center rounded-full">
                              <div className="bg-[#E0E2E7] w-[30px] h-[30px] flex items-center justify-center rounded-full">
                                <IoLocationOutline size={20} color="#667085" />
                              </div>
                            </div>
                            <div className="flex gap-2 items-center">
                              <p className="text-base text-[#1A1C21] font-medium tracking-[0.07px]">
                                NIN
                              </p>
                            </div>
                          </div>
                          {driverDetail?.data?.driver?.nin ? (
                            <ImageFullScreen
                              src={driverDetail?.data?.driver?.nin}
                              alt={driverDetail?.data?.driver?.name}
                            />
                          ) : (
                            "No Image"
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                {driverDetail ? (
                  <div className="w-full">
                    <div className="w-full px-6 py-6 bg-white rounded-lg shadow">
                      <div className="flex justify-between items-center mb-4">
                        <h1 className="text-[18px] text-[#1A1C21] font-medium tracking-[0.09px] mb-4">
                          Transactions
                        </h1>
                        <div className="flex items-center gap-4">
                          <select
                            value={filterType}
                            onChange={(e) => setFilterType(e.target.value)}
                            className="px-4 py-2 border rounded-md"
                          >
                            <option value="">All Type</option>
                            <option value="credit">Credit</option>
                            <option value="debit">Debit</option>
                          </select>
                          <button
                            onClick={openModal}
                            className="text-white bg-blue-500 ease-out duration-500 rounded-md font-medium text-sm hover:text-base py-2 px-2"
                          >
                            View History
                          </button>
                        </div>
                      </div>
                      <div className="overflow-x-auto w-full">
                        <table className="w-full text-sm">
                          <thead className="bg-gray-100">
                            <tr className="border-b text-center">
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                No.
                              </th>
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                Description
                              </th>
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                Amount
                              </th>
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                Type
                              </th>
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                Created
                              </th>
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                Time
                              </th>
                              <th className="px-4 py-2 text-base font-medium text-[#1A1C21]">
                                Updated
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {status === "failed" ? (
                              <tr>
                                <td colSpan="12" className="text-center py-4">
                                  <div className="flex justify-center items-center">
                                    Error. {error}.{" "}
                                    <span className="text-base font-semibold ">
                                      {" "}
                                      Try again later
                                    </span>
                                  </div>
                                </td>
                              </tr>
                            ) : status === "loading" ? (
                              <tr>
                                <td colSpan="12" className="text-center py-4">
                                  <div className="m-auto">
                                    <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                                  </div>
                                </td>
                              </tr>
                            ) : filteredTransactions?.length === 0 ? (
                              <tr>
                                <td colSpan="12" className="text-center py-4">
                                  No data
                                </td>
                              </tr>
                            ) : (
                              filteredTransactions?.map(
                                (transaction, index) => (
                                  <tr
                                    key={transaction.id}
                                    className="border-b text-center"
                                  >
                                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                                      {index + 1}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                                      {transaction.description}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                                      <span className="mr-1">&#8358;</span>
                                      {formatNumberWithCommas(
                                        transaction.amount || "0"
                                      )}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                                      <span
                                        className={`px-2 py-1 rounded-full text-white ${
                                          transaction.transaction_type ===
                                          "credit"
                                            ? "bg-green-500"
                                            : "bg-red-500"
                                        }`}
                                      >
                                        {transaction.transaction_type ===
                                        "credit"
                                          ? "Credit"
                                          : "Debit"}
                                      </span>
                                    </td>
                                    <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                                      {formatCreatedAt(transaction.created_at)}
                                    </td>
                                    <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                                      {formatTime(transaction.created_at)}
                                    </td>
                                    <td className="px-4 py-2 text-sm whitespace-nowrap text-[#1A1C21]">
                                      {formatCreatedAt(transaction.updated_at)}
                                    </td>
                                  </tr>
                                )
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>No transactions available.</p>
                )}
              </div>

              {isModalOpen && (
                <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-50 flex items-center justify-center">
                  <div className="bg-white p-12 rounded-lg max-w-6xl w-full max-h-[90vh] overflow-y-auto">
                    <div className="flex flex-col mb-4">
                      <div className="flex justify-between items-center">
                        <h2 className="text-2xl font-semibold text-gray-500 mt-4 mb-4">
                          History
                        </h2>
                        <button
                          onClick={closeModal}
                          className="px-2 py-2 m-4 text-white bg-blue-500 ease-out duration-500 rounded-md font-medium text-sm hover:text-base"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                    <div>
                      <table className="w-full text-sm text-center text-gray-500">
                        <thead className="text-sm text-gray-700 bg-gray-100">
                          <tr>
                            <th className="px-4 py-4">No</th>
                            <th className="px-4 py-4">Order Id</th>
                            <th className="px-4 py-4">Amount</th>
                            <th className="px-4 py-4">Status</th>
                            <th className="px-4 py-4">Created At</th>
                            <th className="px-4 py-4">Updated At</th>
                          </tr>
                        </thead>
                        <tbody>
                          {driverDetail?.data?.history?.map(
                            (historyItem, index) => (
                              <tr key={historyItem.id}>
                                <td className="px-4 py-4">{index + 1}</td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                  <Link
                                    to={`/dashboard/orders/orders-detail/${historyItem.order_id}`}
                                  >
                                    <p className="px-4 py-1 text-base text-center font-semibold text-blue-600">
                                      {historyItem.order_id}
                                    </p>
                                  </Link>
                                </td>

                                <td className="px-4 py-4">
                                  <p>
                                    <span className="mr-1">&#8358;</span>
                                    {formatNumberWithCommas(
                                      historyItem.total_amount
                                    )}
                                  </p>
                                </td>
                                <td className="px-4 py-4">
                                  {historyItem.status}
                                </td>
                                <td className="px-4 py-4">
                                  {formatCreatedAt(historyItem.created_at)}
                                </td>
                                <td className="px-4 py-4">
                                  {formatCreatedAt(historyItem.updated_at)}
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            "null"
          )}
        </div>
      )}
    </div>
  );
};

export default DriverDetail;
