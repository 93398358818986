import React, { useEffect } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import { ImSpinner2 } from "react-icons/im";
import { fetchAutoMessage } from "../../redux/slices/ceoSlice";
import ModalAutoEmailMessage from "../../components/modals/user/ModalAutoEmailMessage";

const UserAutoEmailMessage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAutoMessage());
  }, [dispatch]);

  const autoMessage = useSelector((state) => state.ceo?.autoMessage);

  const status = useSelector((state) => state.ceo?.status);
  const error = useSelector((state) => state.ceo?.error);

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            Auto Email Message
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Users</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              Auto Email Message
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <button className="relative w-72">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <HiOutlineSearch className=" text-gray-500 text-2xl" />
          </div>
        </button>
        <div>
          <ModalAutoEmailMessage />
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-3 text-left">Subject</th>
                <th className="px-4 py-4 text-left">Message</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="8" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : !autoMessage ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                <tr>
                  <td>
                    <div className="flex items-center gap-4">
                      <textarea
                        value={autoMessage.data.subject}
                        readOnly
                        cols="20"
                        rows="10"
                        className="w-full p-4 rounded-lg bg-gray-100"
                      ></textarea>
                    </div>
                  </td>
                  <td className="px-4 py-4">
                    <div className="flex items-center gap-4">
                      <textarea
                        value={autoMessage.data.message}
                        readOnly
                        cols="20"
                        rows="10"
                        className="w-full p-4 rounded-lg bg-gray-100"
                      ></textarea>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {autoMessage.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default UserAutoEmailMessage;
