import React, { useEffect, useState } from "react";
import { HiChevronRight, HiOutlineSearch } from "react-icons/hi";
// import { IoEyeOutline } from "react-icons/io5";

import { Link } from "react-router-dom";

import {
  formatCreatedAt,
  formatNumberWithCommas,
  formatTime,
} from "../../utils/commonUtils";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllTranscations, fetchUsers } from "../../redux/slices/userSlice";

import { ImSpinner2 } from "react-icons/im";

const UserTranscations = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filterType, setFilterType] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllTranscations());
    dispatch(fetchUsers());
  }, [dispatch]);

  const allTranscations = useSelector((state) => state.user?.allTranscations);

  const usersList = useSelector((state) => state.user?.users);

  const status = useSelector((state) => state.user?.status);
  const error = useSelector((state) => state.user?.error);

  const getUserNameFromId = (userId) => {
    const user = usersList?.find((user) => user.id === parseInt(userId, 10));
    if (!user) {
      return { name: "Unknown", email: "N/A", phone: "N/A" };
    }

    return {
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      phone: user.phone,
    };
  };

  const enrichedUsers = allTranscations?.map((transaction) => {
    const userInfo = getUserNameFromId(transaction.user_id);
    return {
      ...transaction,
      name: userInfo.name,
      email: userInfo.email,
      phone: userInfo.phone,
    };
  });

  const filteredUsers = enrichedUsers?.filter((user) => {
    const searchUpper = search.toUpperCase();
    const matchesSearch =
      searchUpper === "" ||
      user.name.toUpperCase().includes(searchUpper) ||
      user.email.toUpperCase().includes(searchUpper) ||
      user.phone.toUpperCase().includes(searchUpper);

    const matchesFilterType =
      filterType === "" ||
      user?.transaction_type?.toLowerCase() === filterType.toLowerCase();

    return matchesSearch && matchesFilterType;
  });

  // Calculate the index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentUserList = filteredUsers.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

  // Function to handle pagination
  const nextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div className="p-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="w-full h-auto text-2xl mb-2 text-[#333843] font-medium">
            {/* Users ({usersList.length}) */}
            General Transcations
          </h1>

          <div className="flex justify-left items-center gap-2 mt-4">
            <Link to="/dashboard">
              <p className="text-[14px] text-[#1F7BF4] font-medium hover:underline">
                Dashboard
              </p>
            </Link>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">Users</p>

            <HiChevronRight className=" text-gray-500" />
            <p className="text-[14px] text-gray-500  font-medium">
              General Transcations
            </p>
          </div>
        </div>
      </div>
      <div className="flex mt-4 justify-between mb-4">
        <div>
          <button className="relative w-72">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <HiOutlineSearch className=" text-gray-500 text-2xl" />
            </div>
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5"
              placeholder="Search user..."
              onChange={(e) => setSearch(e.target.value)}
            />
          </button>
        </div>
        <div>
          <select
            value={filterType}
            onChange={(e) => setFilterType(e.target.value)}
            className="px-4 py-2 border rounded-md"
          >
            <option value="">All Type</option>
            <option value="credit">Credit</option>
            <option value="debit">Debit</option>
          </select>
        </div>
      </div>

      <div className="bg-white rounded-lg w-full p-4 h-auto shadow-md ">
        <div className="relative overflow-x-auto rounded-t-lg">
          <table className="w-full text-base text-left text-gray-500">
            <thead className="text-sm text-gray-700 bg-gray-50 whitespace-nowrap">
              <tr>
                <th className="px-4 py-3 text-center">No.</th>
                <th className="px-4 py-4 text-left">Name</th>
                <th className="px-4 py-4 text-center">Amount</th>
                <th className="px-4 py-4 text-center">Old Balance</th>
                <th className="px-4 py-4 text-center">New Balance</th>
                <th className="px-2 py-3 text-center"> Description</th>
                <th className="px-2 py-3 text-center">Type</th>
                <th className="px-2 py-3 text-center">Created</th>
                <th className="px-2 py-3 text-center">Time</th>
                <th className="px-2 py-3 text-center">Updated</th>
              </tr>
            </thead>
            <tbody className="text-sm text-left">
              {status === "failed" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      Error. {error}.{" "}
                      <span className="text-base font-semibold ">
                        {" "}
                        Try again later
                      </span>
                    </div>
                  </td>
                </tr>
              ) : status === "loading" ? (
                <tr>
                  <td colSpan="12" className="text-center py-4">
                    <div className="flex justify-center items-center">
                      <ImSpinner2 className="animate-spin h-8 w-8 text-gray-500" />
                    </div>
                  </td>
                </tr>
              ) : currentUserList.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-gray-500 py-4">
                    No Data
                  </td>
                </tr>
              ) : (
                currentUserList?.map((user, index) => (
                  <tr key={user.id}>
                    <td className="px-4 py-4">
                      <p className=" text-base text-center font-semibold">
                        {indexOfFirstItem + index + 1}
                      </p>
                    </td>
                    <td className="px-4 py-4">
                      <div className="flex flex-col text-left gap-2">
                        <Link
                          to={`/dashboard/user/user-transcation/${user.user_id}`}
                        >
                          <p className=" text-blue-600 text-base font-semibold">
                            {getUserNameFromId(user.user_id).name}
                          </p>
                          <p>{getUserNameFromId(user.user_id).email}</p>
                          <p>{getUserNameFromId(user.user_id).phone}</p>
                        </Link>

                        {/* <p className=" text-gray-500">{user.email}</p>
                        <p className=" font-bold">{user.phone}</p> */}
                      </div>
                    </td>
                    <td className="px-2 py-2 text-center font-semibold">
                      <span className="mr-1">&#8358;</span>
                      {formatNumberWithCommas(user.amount)}
                    </td>
                    <td className="px-2 py-2 text-center font-semibold">
                      <span className="mr-1">&#8358;</span>
                      {user.old_balance
                        ? formatNumberWithCommas(user.old_balance)
                        : 0}
                    </td>
                    <td className="px-2 py-2 text-center font-semibold">
                      <span className="mr-1">&#8358;</span>
                      {user.new_balance
                        ? formatNumberWithCommas(user.new_balance)
                        : 0}
                    </td>
                    <td className="px-4 py-4 text-center ">
                      {user.description}
                    </td>
                    <td className="px-4 py-2 text-sm text-[#1A1C21]">
                      <span
                        className={`px-2 py-1 rounded-full text-white ${
                          user.transaction_type === "credit"
                            ? "bg-green-500 font-medium"
                            : "bg-red-500 font-medium"
                        }`}
                      >
                        {user.transaction_type === "credit"
                          ? "Credit"
                          : "Debit"}
                      </span>
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.created_at)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap font-semibold">
                      {formatTime(user.created_at)}
                    </td>
                    <td className="px-2 py-2 text-center whitespace-nowrap">
                      {formatCreatedAt(user.updated_at)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <div className="flex justify-between px-[24px] py-[15px] items-center">
          <p className="text-[14px] py-4 font-medium text-[#667085] tracking-[0.07px]">
            Showing {indexOfFirstItem + 1} - {indexOfLastItem} from{" "}
            {allTranscations.length}
          </p>

          <div className="flex gap-[8px]">
            <button
              onClick={prevPage}
              disabled={currentPage === 1}
              className={`px-4 py-2 rounded-md ${
                currentPage === 1
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Previous
            </button>

            <button
              onClick={nextPage}
              disabled={currentPage === totalPages}
              className={`px-4 py-2 rounded-md ${
                currentPage === totalPages
                  ? "bg-gray-300 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserTranscations;
