import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const sections = [
  {
    title: "Dashboard",
    items: [
      "Dashboard Note",
      "Laundry Business Report",
      "Shopping Business Report",
      "Investment Business Report",
    ],
  },
  {
    title: "Fee Settings",
    items: [
      "Laundry Fee Setting",
      "Shopping Fee Setting",
      "Investment Fee Setting",
      "Wallet Transaction Fee Setting",
    ],
  },
  {
    title: "Bank Payment",
    items: [
      "Cart Shopping",
      "Personalize Shopping",
      "Fuel/Gas",
      "Wallet Funding",
      "Laundry",
      "Completed Bank Transfer",
      "Requested Transfers",
    ],
  },
  {
    title: "Shopping Orders",
    items: [
      "Cart Orders",
      "All Orders",
      "Pending Orders",
      "Express Orders",
      "Ongoing Orders",
      "Pending Deliveries",
      "Delivered Orders",
      "Pay on Delivery",
      "Future Deliveries",
      "Completed Orders",
      "Cancelled Orders",
      "Shopping Reported Orders",
      "Shopping Subscription",
    ],
  },
  { title: "Monitor Orders", items: ["Monitor Orders"] },
  {
    title: "Stores",
    items: [
      "Market",
      "Stores",
      "Fast Food",
      "Pharmacy",
      "Categories",
      "Products",
      "Trending Products",
      "Price Feedback",
    ],
  },
  {
    title: "Producers",
    items: [
      "All Producers",
      "All Sectors",
      "Pending Product Approval",
      "Approved Products",
      "Active Subscribers",
      "Free Subscribers",
      "Admin Subscriptions",
    ]
  },
  {
    title: "Vendor",
    items: [
      "All Vendors",
      "Pending Product Approval",
      "Approved Products",
    ]
  },
  {
    title: "Trade Fairs",
    items:[
      "Pending Trade Fairs",
      "Approved Trade Fairs",
    ]
  },
  {
    title: "Laundry",
    items: [
      "Laundry cart orders",
      "All Laundry Orders",
      "Express Orders",
      "Pending Payment Laundry",
      "Completed Orders",
      "Cancelled Orders",
      "Laundry Reported Orders",
      "Laundry Store",
      "Laundry Drop Off",
      "Laundry Subscription",
    ],
  },
  {
    title: "Track laundry Order",
    items: [
      "Order Placed",
      "Items received",
      "Ongoing Orders",
      "Ready for delivery",
      "Items Delivered",
      "Disputed Orders",
    ],
  },
  {
    title: "Shoppers",
    items: [
      "All Shoppers",
      "Shopper Withdrawals",
      "Shopper Remittance",
      "Shopper Payment Request",
      "Completed Payment",
      "Shopper Promotion",
      "Store Locator",
      "Shopper Deletion Request",
      "seller-info",
    ],
  },
  {
    title: "Drivers",
    items: [
      "All Drivers",
      "Driver Withdrawals",
      "Driver Remittance",
      "Driver Payment Request",
      "Driver Push Orders",
      "Driver Deletion Request",
      "Completed Payment",
      "Driver Promotion",
    ],
  },
  {
    title: "Users",
    items: [
      "All Users",
      "General Transcations",
      "VTU Transactions",
      "Top-up Wallet",
      "Wallet Deduction",
      "Shopping Coupon",
      "Laundry Coupon",
      "Isusu Coupon",
      "User Promotions",
      "Email Marketing",
      "Auto Email Message",
      "User Deletion Request",
      "Tippers",
      "Daily ROI",
      "Coupon History",
      "Promotion History",
      "User Birthdays",
    ],
  },
  { title: "Transport", items: ["Transport"] },
  {
    title: "Fuel & Gas",
    items: ["Fuel & Gas"]
  },
  { title: "Location", items: ["Countries", "States", "Cities"] },
  {
    title: "Investment",
    items: [
      "Investments",
      "Investors",
      "Projects",
      "Isusu",
      "Isusu Approval Request",
      "Isusu Receivers",
      "Isusu Defaulters",
    ],
  },
  {
    title: "Legal",
    items: ["Terms & Conditions", "Privacy Policy"],
  },
];

const Accordion = ({ formData, setFormData }) => {
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    // Ensure formData.functions is an array when it's empty initially
    if (!formData.functions) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        functions: [],
      }));
    }
  }, [formData.functions, setFormData]);

  const handleCheckboxChange = (section, item) => {
    setFormData((prevFormData) => {
      const sectionFunctions = prevFormData.functions || [];

      if (sectionFunctions.includes(item)) {
        return {
          ...prevFormData,
          functions: sectionFunctions.filter((func) => func !== item),
        };
      } else {
        return {
          ...prevFormData,
          functions: [...sectionFunctions, item],
        };
      }
    });
  };

  const toggleSection = (section) => {
    setExpandedSection((prevExpandedSection) =>
      prevExpandedSection === section ? null : section
    );
  };

  return (
    <div className="w-full mx-auto">
      <div className="flex flex-col mt-4 gap-[14px] w-full h-auto px-4 py-4 bg-white rounded-lg shadow">
        <h3 className="text-lg font-medium mb-1">Select Functions</h3>
        {sections.map((section) => (
          <div key={section.title} className="mb-4">
            <button
              className="w-full text-left px-4 py-2 border-2 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none flex justify-between items-center"
              onClick={() => toggleSection(section.title)}
            >
              {section.title}
              {expandedSection === section.title ? (
                <FaChevronUp className="text-gray-500" />
              ) : (
                <FaChevronDown className="text-gray-500" />
              )}
            </button>
            {expandedSection === section.title && (
              <div className="px-4 py-2 border-lg mt-2 border-gray-200">
                {section.items.length > 0 ? (
                  section.items.map((item) => (
                    <div key={item} className="flex items-center my-2">
                      <input
                        type="checkbox"
                        id={`${section.title}-${item}`}
                        name={`${section.title}-${item}`}
                        value={item}
                        checked={formData.functions?.includes(item)}
                        onChange={() =>
                          handleCheckboxChange(section.title, item)
                        }
                        className="mr-2"
                      />
                      <label htmlFor={`${section.title}-${item}`}>{item}</label>
                    </div>
                  ))
                ) : (
                  <div className="text-gray-500">No items available</div>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Accordion;
